import { Component, Vue } from "vue-property-decorator";

import { STHeader, STFooter } from "@/layout";
import { StCommon } from "../Common";
import { InstructionsModel } from "@/models";

export class InputForm {
    iid = '';
    content = '';
}

type response = { [key: string]: unknown };

@Component<ApStudentlnstructionsIndexController>({
    components: {
        STHeader, STFooter
    }
})
export default class ApStudentlnstructionsIndexController extends Vue {
    private inputForm = new InputForm();
    private token = window.localStorage.getItem('studentToken') as string;

    public async created() {
        const item: { [key: string]: string } = {
            iid: '2',
            token: this.token,
        }
        const data = await InstructionsModel.stGet(item) as response;
        this.assignDataToForm(data);
    }

    private assignDataToForm(data: response) {
        StCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as { [key: string]: string };

        this.inputForm.iid = item.iid;
        this.inputForm.content = item.content;
    }
}